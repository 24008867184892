import { useState } from "react";
import { Form, Link, useActionData, useSubmit } from "@remix-run/react";
import {
  TextInput,
  PasswordInput,
  Button,
  Paper,
  Text,
  Group,
  Anchor,
  Box,
  Code,
} from "@mantine/core";
import { useForm, isEmail, isNotEmpty, matchesField } from "@mantine/form";
import { getRoute } from "~/routes";
import { IconArrowRight, IconConfetti } from "@tabler/icons-react";
import { SocialLoginButtons } from "~/components/AppShell/Auth/SocialLoginButtons";
import { useTranslation } from "react-i18next";
import { useNavigation } from "react-router";

export function Register() {
  const { t } = useTranslation();
  const submit = useSubmit();
  const actionData = useActionData();
  const navigation = useNavigation();
  const [isRegistered, setIsRegistered] = useState(false);

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    validate: {
      email: isEmail(t("invalid_email")),
      password: isNotEmpty(t("password_required")),
      confirmPassword: matchesField("password", t("passwords_do_not_match")),
    },
  });

  const handleSubmit = (values: typeof form.values) => {
    submit(values, { method: "post", action: getRoute("register") });
  };
  const isSubmitting = navigation.state === "submitting";

  if (actionData?.status === 200 && !isRegistered) {
    setIsRegistered(true);
  }

  if (isRegistered) {
    return (
      <Paper radius="md" pb="sm" ta="center">
        <IconConfetti size={"40"}></IconConfetti>
        <Text size="xl" fw={500} ta="center" mb="lg" mt={"lg"}>
          {t("registered_successfully")}
        </Text>
        <Text ta="center" mb={"sm"}>
          {t("email_confirmation_sent")} <Code>{actionData?.email}</Code>
        </Text>
        <Text ta="center">{t("please_check_your_email")}</Text>
      </Paper>
    );
  }

  return (
    <Paper radius="md">
      <SocialLoginButtons />
      <Form method="POST" onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          label={t("email")}
          placeholder="you@example.com"
          required
          {...form.getInputProps("email")}
        />
        <PasswordInput
          label={t("password")}
          placeholder={t("password")}
          required
          mt="md"
          {...form.getInputProps("password")}
        />
        <PasswordInput
          label={t("confirm_password")}
          placeholder={t("confirm_password")}
          required
          mt="md"
          {...form.getInputProps("confirmPassword")}
        />
        {actionData?.message && actionData.status !== 200 && (
          <Text c="red" size="sm" mt="sm">
            {actionData.message}
          </Text>
        )}
        <Group justify="space-between" mt="lg">
          <Anchor
            component={Link}
            to={getRoute("login")}
            type="button"
            c="dimmed"
            size="sm"
          >
            {t("already_have_account")}
          </Anchor>
          <Button
            loading={isSubmitting}
            disabled={isSubmitting}
            rightSection={<IconArrowRight size={14} />}
            type="submit"
          >
            {t("create_account")}
          </Button>
        </Group>
      </Form>
    </Paper>
  );
}
